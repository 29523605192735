@import './include.css';
@import './reset.css';

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}

#root {
  display: flex;
}

body {
  font-family: Inter, sans-serif;
}
